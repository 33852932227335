const TOPICMAPVERSION = '1.23.0';
const TOPICMAPHASH = '#8479f9ac58d2a00ee542ea767f8be875c07f6502';

export const getTopicMapVersion = () => {
	/*eslint-disable no-useless-concat*/
	if (TOPICMAPVERSION === '%TOPICMAP' + '_' + 'VERSION%') {
		return 'dev-hot-reload';
	} else {
		return TOPICMAPVERSION;
	}
};
export const getTopicMapHash = () => {
	if (TOPICMAPHASH === '%TOPICMAP' + '_' + 'HASH%') {
		return '#dev-hot-reload';
	} else {
		return TOPICMAPHASH;
	}
};
