import React from 'react';

const Section = ({ uiStateActions }) => {
	return (
		<span>
			Bitte klicken Sie auf eine der Kapitelüberschriften in den farbig unterlegten Bereichen,
			um sich weitere Informationen zu dem entsprechenden Thema anzeigen zu lassen:
		</span>
	);
};
export default Section;
