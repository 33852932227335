import React from 'react';

const Section = ({ uiStateActions }) => {
	return (
		<span>
			Bitte wählen Sie eine der folgenden farbigen Schaltflächen, um sich weitere
			Informationen zu dem entsprechenden Thema anzeigen zu lassen:
		</span>
	);
};
export default Section;
